import React from "react";
import "./App.css";
import StartUp from "./utils/StartUp";

const App = () => {
  return (
    <div className="App">
      <StartUp />
    </div>
  );
};

export default App;
