import React from "react";
import AppRouter from "./AppRouter";

const StartUp = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
};

export default StartUp;
